import React from "react"
import parse from "html-react-parser"
import "./community.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



function Community({data}) {


  let headerContent = data?.headerContent
   let partnerCards = data?.partnerCards
  return (
    <section className={`text-block-section community type-2`}>
      <div className="container">
        <div className="text-blog-wrapper">
          <div
            className="text-block-header"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="10"
          >
            {parse(headerContent)}
          </div>
          <div className="text-block-body-community ">
            {partnerCards.map(( e,index ) => {

              return (
                <div
                  key={index}
                  className="text-block-community"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-delay="10"
                >
                  <div className="text-block__index ">
                    <div className="text-block__index__img">
                      <a target="_blank" href={e?.link?.url} style={{width:"100%", display:"block"}}>
                      <GatsbyImage
                        objectFit={"fill"}
                        className="fill-image"
                        image={getImage(e?.partnerLogo?.localFile)}
                      />
                      </a>
                    </div>
                  </div>
                  <div className="text-block__title text-block__content">
                    {parse(e?.partnerText)}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Community
