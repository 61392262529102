import { graphql } from "gatsby"
import React, { useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import cta1 from "../images/blog-image1.webp"
import "../style/_blog-template.scss"
import "../style/templates/default.scss"
import {
  Banner,
  ErrorBoundary,
  Hero,
  Layout,
  MindBodyPage,
  Seo,
  TextBlocks,
} from "../../index.js"

import Community from "../components/Community/Community.jsx"
import "../style/_customstyles.scss"
export default function Home({ data, pageContext, location }) {
  let blocks = Boolean(Object.keys(data.home.blocks[0]).length)
  const blockJson = JSON.parse(data?.home.blocksJSON)
  if (!blocks && !blockJson) return null

  let { heroBlock } = data?.home?.blocks[0]
  let communityBlock = data?.home?.blocks[1]?.communityBlock

  // handle toggle form
  return (
    <Layout pathname={location.pathname}>
      <Seo
        title={data.home.seo?.title}
        description={data.home.seo?.metaDesc}
        featuredImage={
          data.home.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />
      {heroBlock && (
        <Hero
          heroImage={heroBlock.heroImage}
          heroTitle={{
            normalTitle: heroBlock.heroTitle,
            strokeTitle: heroBlock.heroSubTitle,
          }}
          color="#ff7f7f"
          className="our-team-hero-customize"
        />
      )}
      {communityBlock && <Community data={communityBlock} />}
      {blockJson && (
        <div className="post__container default__post page-container">
          <div className="post__content__container">
            {blockJson.map((block, index) => {
              if (block.name === "acf/cta-block") {
                return (
                  <div className="post__CTA d-flex-row">
                    <div
                      className="post__CTA__left-side"
                      style={{ backgroundImage: `url('${cta1}')` }}
                    >
                      <a
                        href={block?.attributes?.data?.cta_button?.url}
                        className="post__CTA__button"
                        target={block?.attributes?.data?.cta_button?.target}
                      >
                        {block?.attributes?.data?.cta_button?.title}
                      </a>
                      <StaticImage
                        className="dot-line"
                        src="../images/blog-image2.png"
                        alt="arrow"
                      />
                    </div>

                    <div className="post__CTA__right-side d-flex-column">
                      <span></span>
                      <StaticImage
                        className="arrow-line"
                        src="../images/three-arrow.png"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="post__CTA__content d-flex-column"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-delay="10"
                      data-aos-duration="2000"
                    >
                      <h2
                        className="post__CTA__title stroke-text"
                        style={{
                          WebkitTextStrokeColor: `${block?.attributes?.data?.cta_stroke_color}`,
                        }}
                      >
                        {block?.attributes?.data?.cta_stroke_title}
                      </h2>
                      <div
                        className="post__CTA__title normal-text"
                        dangerouslySetInnerHTML={{
                          __html: block?.attributes?.data?.cta_title,
                        }}
                      ></div>
                      <div
                        className="post__CTA__content__content"
                        dangerouslySetInnerHTML={{
                          __html: block?.attributes?.data?.cta_content,
                        }}
                      ></div>
                    </div>
                  </div>
                )
              }
              if (block.name.includes("image")) {
                return (
                  <div className="post-content rich-text-edit">
                    <div className="post__excerpt post-image">
                      <img src={block?.attributes?.url} alt="post images" />
                    </div>
                  </div>
                )
              }
              if (block.name.includes("embed")) {
                if (block?.attributes?.url.includes("vimeo.com")) {
                  const videodId = block?.attributes?.url
                    .split("/")
                    .slice(-1)[0]
                  return (
                    <div className="post-content rich-text-edit post__embed">
                      <div className="post__excerpt post__youtube">
                        <div>
                          <iframe
                            src={`https://player.vimeo.com/video/${videodId}`}
                            height="300"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                            className="responsive-iframe"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (block?.attributes?.url.includes("youtu.be")) {
                  const videodId = block?.attributes?.url
                    .split("/")
                    .slice(-1)[0]
                  return (
                    <div className="post-content rich-text-edit post__embed">
                      <div className="post__excerpt post__youtube">
                        <div>
                          <iframe
                            src={`https://www.youtube.com/embed/${videodId}`}
                            height="400"
                            width="100%"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            className="responsive-iframe"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  )
                }
              }

              return (
                <div className="post-content rich-text-edit">
                  <div
                    className="post__excerpt"
                    dangerouslySetInnerHTML={{
                      __html: block?.originalContent,
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!, $lang: String!) {
    home: wpPage(slug: { eq: $slug }, language: { slug: { eq: $lang } }) {
      slug
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocksJSON
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfQuoteBlock {
          quoteBlock {
            quoteLeftBy
            quoteLeftTitle
            showQuoteBlock
            quoteRightBottomText
            quoteRightTopText
          }
        }
        ... on WpAcfTwoImagesBlock {
          twoImagesBlock {
            firstBlock {
              buttonText
              buttonLink {
                ... on WpPage {
                  uri
                }
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(fit: COVER, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            secondBlock {
              buttonText
              buttonLink {
                ... on WpPage {
                  uri
                }
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(fit: COVER, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfImageWithContentBlock {
          imageContentBlock {
            mainImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundColor
            contentColor
            haveButton
            buttonText
            buttonLink {
              ... on WpPage {
                uri
              }
            }
            isReverse
            lightButton
            mainContent
            mainTitle
            titleColor
          }
        }
        ... on WpAcfButtonBlock {
          buttonBlock {
            buttonText
            buttonLink {
              url
            }
          }
        }
        ... on WpAcfTextBlock {
          textBlock {
            textBlockSubTitle
            textBlockTitle
            textBlockTitleStroke
            buttonText
            textCard {
              textCardContent
              textCardTitle
              textCardImage {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
        ... on WpAcfCommunityBlock {
          communityBlock {
            partnerCards {
              link {
                url
              }
              partnerLogo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, height: 142, quality: 100)
                  }
                }
              }
              partnerText
            }
            headerContent
          }
        }
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date] }
      limit: 4
      filter: { language: { slug: { eq: $lang } } }
    ) {
      nodes {
        title
        excerpt
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(quality: 90, fit: COVER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
